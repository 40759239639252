import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const PositionCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--simon-blue)',
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const PositionRow = withStyles((theme) => ({
  root: {
    fontSize: 12,
    '&:nth-of-type(odd)': {
      backgroundColor: 'var(--bg-color)',
    },
  },
}))(TableRow);

export const CompositionTable = styled.table`
  width: 100%;
  max-width: 100%;
  border: 0px;
  font-size: 14px;
  border-collapse: collapse;

  td {
    padding: 5px;
  }

  td:nth-child(even) {
    text-align: right;
  }

  td:nth-child(odd) {
    text-align: left;
  }

  thead {
    font-weight: 700;
    td {
      border-bottom: 2px solid #dee2e6;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #dee2e6;
    }
    tr:last-child {
      border-bottom: 1px solid white;
    }
  }
`;
