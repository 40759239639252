import React, { useEffect } from 'react';
import GlobalStyles from '../../styles/GlobalStyles';
import Header from '../../components/Header';
import MarginCard from '../../components/MarginCard';
import { Grid, Box, Divider } from '@material-ui/core';
import PositionTable from '../../components/Tables/position';
import ChartLastDaysPerform from '../../components/Charts/lastDaysPerform';
import ChartFunding from '../../components/Charts/funding';
import ChartFundingLast from '../../components/Charts/fundingLast';

import styled from 'styled-components';
import Accounts from '../../components/Accounts';
import simonAuth from '../../providers/auth/simonAuth';
import { noPower } from '../noPower';
import ChartBorrow from '../../components/Charts/borrow';
export const Container = styled.div`
  width: 100%;
`;

const App = () => {
  const { user, isLoading, logout } = simonAuth();
  const userHasNoPower = !isLoading && !user.simonAdm;
  useEffect(() => {
    if (userHasNoPower) document.body.style.backgroundColor = '#eaf2fe';
  }, [userHasNoPower]);
  if (userHasNoPower) {
    return noPower({ user, logout });
  }
  return (
    <>
      <Header />
      <Container>
        <Box m={'auto'} maxWidth={1024} p={1}>
          <Grid container spacing={2} mt={2} pt={2}>
            <Grid item xs={4}>
              <MarginCard marginType="futures" />
            </Grid>
            <Grid item xs={4}>
              <MarginCard marginType="delivery" />
            </Grid>
            <Grid item xs={4}>
              <MarginCard marginType="borrow" />
            </Grid>
            <Grid item xs={12}>
              <ChartLastDaysPerform />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Accounts />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <PositionTable />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChartFunding />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChartFundingLast />
            </Grid>
            <Grid item xs={12}>
              <ChartBorrow />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <GlobalStyles />
    </>
  );
};

export default App;
