import Box from '@material-ui/core/Box';
import React from 'react';
import { Link } from 'react-router-dom';
import Eye from '../../images/simon-white-txt.png';
import {
  Logo,
  // StyledTypography as Typography,
  StyledAppBar as AppBar,
} from './styles';

/*
const Header = (props) => {
  return (
    <Container>
      <img alt="Logo" src={Eye} {...props} />
    </Container>
  );
};

export default Header;
*/

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
// import { useAuth0 } from '@auth0/auth0-react';
import { Avatar } from '@material-ui/core';
import simonAuth from '../../providers/auth/simonAuth';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElLeft, setAnchorElLeft] = React.useState(null);
  const { loginWithRedirect, logout, user, isLoading } = simonAuth();
  const open = Boolean(anchorEl);
  const leftOpen = Boolean(anchorElLeft);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuLeft = (event) => {
    setAnchorElLeft(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElLeft(null);
  };
  /* <MenuItem key="profilemenu" onClick={handleClose}>
            Profile
          </MenuItem>,
          <MenuItem key="accmenu" onClick={handleClose}>
            My account
          </MenuItem>, */
  const menus =
    !isLoading && user
      ? [
          <MenuItem key="MenuItem1" disabled={true}>
            {user.simonAdm ? 'Adm' : 'Cotista'}: {user.name}
          </MenuItem>,
          <MenuItem key="MenuItem2 " onClick={() => logout()}>
            Log Out
          </MenuItem>,
        ]
      : !isLoading && !user
      ? [
          <MenuItem key="loginmenu" onClick={() => loginWithRedirect()}>
            Login
          </MenuItem>,
        ]
      : [];

  const headerButton = !user ? (
    <AccountCircle />
  ) : (
    <Avatar alt={user.name} src={user.picture} />
  );

  return (
    <Box displayPrint="none">
      <AppBar position="static">
        <Toolbar mb={10}>
          <Box display="flex" flexGrow={1}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleMenuLeft}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElLeft}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={leftOpen}
              onClose={handleClose}
            >
              {user &&
                user.simonAdm && [
                  <Link
                    to="/monitor"
                    style={{
                      textDecoration: 'none',
                      display: 'block',
                      color: 'var(--menu-text)',
                    }}
                    key="Monitor"
                  >
                    <MenuItem onClick={handleClose}>Monitor</MenuItem>
                  </Link>,
                  <Link
                    to="/reportDetailed"
                    style={{
                      textDecoration: 'none',
                      display: 'block',
                      color: 'var(--menu-text)',
                    }}
                    key="ReportDetailed"
                  >
                    <MenuItem onClick={handleClose}>Report (Detailed)</MenuItem>
                  </Link>,
                ]}
              <Link
                to="/report"
                style={{
                  textDecoration: 'none',
                  display: 'block',
                  color: 'var(--menu-text)',
                }}
              >
                <MenuItem onClick={handleClose}>Report</MenuItem>
              </Link>
            </Menu>
            <Logo alt="Simon" src={Eye} />
          </Box>

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {headerButton}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {menus}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

/*
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
<FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={auth}
              onChange={handleChange}
              aria-label="login switch"
            />
          }
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup>
*/
