import { useAuth0 } from '@auth0/auth0-react';
const adm = process.env.REACT_APP_ADM_ROLES;
export default () => {
  const auth = useAuth0();
  if (auth && 'user' in auth && auth.user) {
    auth.user.simonAdm =
      adm
        .split(',')
        .map((e) => e.trim().toLowerCase())
        .indexOf(auth.user.email.trim().toLowerCase()) >= 0;
  }
  return auth;
};
