import React, { useEffect, useState } from 'react';
import GlobalStyles from '../../styles/GlobalStyles';
import Header from '../../components/Header';
import PerformanceTable from '../../components/PerformanceTable';
import ChartPatrimonio from '../../components/Charts/patrimonio';
import ChartPerformBenchmark from '../../components/Charts/performBenchmark';
import Loading from '../../components/Loading';

import {
  position as positionApi,
  accounts as accountsApi,
} from '../../api/last';
import { Box, Grid, Typography, Paper } from '@material-ui/core';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Separator = styled(Typography)`
  border-bottom: solid 1.4px #0d2e72;
`;
export const Details = styled(Typography)`
  color: #0d2e72;
  padding-bottom: 0px;
`;
export const UL = styled.ul`
  list-style-type: none;
`;

const Report = () => {
  const [position, setPosition] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const daysBefore = 3;

  useEffect(() => {
    accountsApi()
      .then((data) => setAccounts(data))
      .catch(console.log);
    positionApi()
      .then((data) =>
        setPosition(
          data.map((e) => {
            e.usdt = Number(e.usdt);
            e.borrowed = Number(e.borrowed);

            return e;
          })
        )
      )
      .catch(console.log);
  }, []);
  if (accounts.length === 0 || position.length === 0) return <Loading />;
  const infos = [
    ' Histórico de rentabilidade em USDT',
    <span key="listPerform">
      <b> DESCONTADO</b> Taxa de Administração
    </span>,
    <span key="listPerform">
      <b> NÃO DESCONTADO</b> Taxa de Performance.
    </span>,
    ' Índice utilizado apenas para fins de comparação, não sendo meta ou parâmetro de performance',
    ' RENTABILIDADE PASSADA NÃO REPRESENTA GARANTIA DE RENTABILIDADE FUTURA',
  ].map((e, i) => (
    <li key={i}>
      <Details variant="caption">{e}</Details>
    </li>
  ));
  return (
    <>
      <Header />
      <Container>
        <Box m={'auto'} maxWidth={1024} p={1} mt={2}>
          <Grid container spacing={3} mt={10} pt={30}>
            <Grid item xs={12}>
              <Separator>INFORMAÇÕES</Separator>
            </Grid>
            <Grid item xs={12}>
              <UL>{infos}</UL>
            </Grid>
            <Grid item xs={12}>
              <Separator>PERFORMANCE HISTÓRICA</Separator>
            </Grid>
            <Grid item xs={12}>
              <PerformanceTable daysBefore={daysBefore} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Separator>GRÁFICO DE PERFORMANCE</Separator>
              <Box component={Paper} mt={3}>
                <ChartPerformBenchmark type="monthly" daysBefore={daysBefore} />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Separator>PATRIMÔNIO</Separator>
              <Box component={Paper} mt={3}>
                <ChartPatrimonio type="table" daysBefore={daysBefore} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <GlobalStyles />
    </>
  );
};

export default Report;
