import { ThemeProvider, withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { createTheme } from '@material-ui/core/styles';
import { position } from '../../api/last';
import { numberFormat, sumByKey } from '../../utils';
import Loading from '../Loading';
import { PositionCell as TD, PositionRow as TR } from './styles';

const styles = (theme) => ({
  root: {
    height: '100vw',
  },

  alignSelf: {
    alignSelf: 'center',
  },
});
const formatter = new Intl.NumberFormat([], {
  style: 'decimal',
  maximumFractionDigits: 1,
});
const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: 'var(--dark-blue)',
      },
      track: {
        color: 'var(--simon-blue)',
        height: 3,
      },
      rail: {
        height: 3,
        color: 'var(--simon-green)',
        borderRadius: 24,
        opacity: 1,
      },
      mark: { width: 5, height: 3 },
    },
  },
});

class PositionTable extends React.Component {
  state = {
    loading: true,
    position: null,
    dataTable: null,
  };

  formatZero(num) {
    const number = Number(num);
    return number === 0 ? '-' : Number(number.toFixed(4));
  }

  async componentDidMount() {
    const response = await position(true);
    this.setState({
      dataTable: response,
      loading: false,
    });
  }

  render() {
    let page;
    if (this.state.loading) {
      page = <Loading />;
    } else {
      const long = sumByKey(this.state.dataTable, 'long');
      const short = Math.abs(sumByKey(this.state.dataTable, 'short'));
      const diff = long - short;
      const slidePosition = (long / (long + short)) * 100;

      const marks = [
        {
          value: 0,
          label: '',
        },
        {
          value: 50.000001,
          label: '',
        },
        {
          value: 100,
          label: '',
        },
        { value: slidePosition, diff, label: formatter.format(diff) },
      ];

      page = (
        <>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="customized table">
              <TableHead>
                <TR>
                  <TD align="right">Symbol</TD>
                  <TD align="right">Notional</TD>
                  <TD align="right">USDT Diff</TD>
                  <TD align="right">Fut. Liq.</TD>
                  <TD align="right">Iso. Liq.</TD>
                  <TD align="right">Borrowed</TD>
                  <TD align="right">Type</TD>
                </TR>
              </TableHead>
              <TableBody>
                {this.state.dataTable.map((row) => (
                  <TR key={row.symbol}>
                    <TD component="th" scope="row">
                      {row.symbol}
                    </TD>
                    <TD align="right">
                      {numberFormat(
                        Number(row.long) + Math.abs(Number(row.short)),
                        0
                      )}
                    </TD>
                    <TD align="right">{this.formatZero(row.diff)}</TD>
                    <TD align="right">{this.formatZero(row.futMargin)}</TD>
                    <TD align="right">{this.formatZero(row.isoMargin)}</TD>
                    <TD align="right">{this.formatZero(row.borrowed)}</TD>
                    <TD align="right">{row.type}</TD>
                  </TR>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="subtitle2"
                    >
                      LONG: {formatter.format(long)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="subtitle2"
                    >
                      SHORT: {formatter.format(short)}
                    </Typography>
                  </Box>
                </Grid>
                <ThemeProvider theme={muiTheme}>
                  {!Number.isNaN(slidePosition) && (
                    <Slider
                      defaultValue={slidePosition}
                      aria-labelledby="discrete-slider-restrict"
                      step={null}
                      valueLabelDisplay="auto"
                      marks={marks}
                      width="55%"
                      disabled
                    />
                  )}
                </ThemeProvider>
              </CardContent>
            </Card>
          </Box>
        </>
      );
    }
    return page;
  }
}
PositionTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PositionTable);
