import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
`;

export const MiddleBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  color: #2d333a;
  background-color: #fff;
  box-shadow: 0 12px 40px rgb(0 0 0 / 12%);
  border-radius: 5px;
  width: 400px;
`;

export const BoxHead = styled.div`
  padding-bottom: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  line-height: 1.5;
  text-align: center;
  flex-shrink: 0;
  display: block;
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
`;
export const Image = styled.img`
  max-height: 52px;
  max-width: 100%;
  vertical-align: top;
`;

export const H1 = styled.h1`
  margin: 24px 0 16px;
  font-size: 24px;
  letter-spacing: 0;
  text-align: center;
  color: var(--menu-text);
  font-weight: 400;
`;

export const Button = styled.button`
  position: relative;
  width: 100%;
  padding: 0 16px;
  margin-top: 24px;
  height: 52px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;

  outline: none;
  cursor: pointer;
  color: var(--menu-text);
  background-color: var(--simon-blue);
  border: 0;
  border-radius: 3px;
  transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
`;
export const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100%;
  padding: 80px 0;
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  border: 0;

  vertical-align: baseline;
  margin-left: auto;
  margin-right: auto;
`;
