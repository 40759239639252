import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{        
        margin: 0;
        padding: 0;
        box-sizing: border-box; 
    }
    html,body,#root{ 
        height: 100%;
        background-color: var(--bg-color);
        width:100vw;
        overflow-x: hidden;
    }
    *, button, input{
        border: 0;
        outline: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
    }

    :root{
        --simon-blue: rgb(30, 172, 228);
        --dark-blue: rgb(24, 137, 182);
        --bg-color: #f5f5f5;
        --simon-green: #38F568;
        --menu-text: 'black';
    }
    @page {
        size: A4;
        margin: 0;
    }
    @media print {
        html, body {
            padding:0px;
            margin:10px;
            width: 210mm;
            height: 297mm;
        }
    }
`;
