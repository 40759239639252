import React, { useEffect, useState } from 'react';
import ChartPerformBenchmark from '../../components/Charts/performBenchmark';
import Loading from '../../components/Loading';
import PerformanceTable from '../../components/PerformanceTable';
import simonAuth from '../../providers/auth/simonAuth';
import GlobalStyles from '../../styles/GlobalStyles';
import { noPower } from '../noPower';

import { Box, Grid, Paper, Typography } from '@material-ui/core';
import {
  accounts as accountsApi,
  position as positionApi,
} from '../../api/last';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Separator = styled(Typography)`
  border-bottom: solid 1.4px #0d2e72;
`;

const Lamina = () => {
  const [position, setPosition] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const { user, isLoading, logout } = simonAuth();
  const userHasNoPower = !isLoading && !user.simonAdm;
  const [useAdmFee] = useState(false);

  useEffect(() => {
    if (userHasNoPower) document.body.style.backgroundColor = '#eaf2fe';
    accountsApi()
      .then((data) => setAccounts(data))
      .catch(console.log);
    positionApi()
      .then((data) =>
        setPosition(
          data.map((e) => {
            e.usdt = Number(e.usdt);
            e.borrowed = Number(e.borrowed);

            return e;
          })
        )
      )
      .catch(console.log);
  }, [userHasNoPower]);

  if (userHasNoPower) {
    return noPower({ user, logout });
  }
  if (accounts.length === 0 || position.length === 0) return <Loading />;

  return (
    <>
      <Container>
        <Box m={'auto'} p={1} mt={2} maxWidth={1024}>
          <Grid container spacing={3} mt={10} pt={30}>
            <Grid item xs={12}>
              <PerformanceTable useAdmFee={useAdmFee} hideInfo />
            </Grid>
            <Grid item xs={12}>
              <Box component={Paper} mt={1}>
                <ChartPerformBenchmark useAdmFee={useAdmFee} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <GlobalStyles />
    </>
  );
};

export default Lamina;
