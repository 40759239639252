import axios from 'axios';
import moment from 'moment';
import PortfolioAnalytics from 'portfolio-analytics';
import { formatDate, getDate, onlyUnique, sortBy, toFixed } from '../utils';
const fetch = require('node-fetch');

const base = 'https://data.simoncapital.ai';
const time = new Date().getTime();
const account = process.env.REACT_APP_ACC;
const URL = {
  // cota: `${base}/cota.csv?tmp=${time}`,
  cotaMean: `${base}/binance-data/binance-cota-${account}.csv?tmp=${time}`,
  btc: `${base}/binance-data/btc.json?tmp=${time}`,
  consolidated: `${base}/binance-data/binance-position-consolidated-${account}.json?tmp=${time}`,
  funding: `${base}/binance-data/binance-funding-daily-${account}.json?tmp=${time}`,
  wallet: `${base}/wallet-tail.csv?tmp=${time}`,
  walletDaily: `${base}/binance-data/binance-wallet-daily-${account}.json?tmp=${time}`,
  benchmarks: `${base}/benchmarks.json`,
  benchmarksInfomoney: `${base}/benchmarks-infomoney.json`,
  // aportes: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR9V_Vvp4083dGyt06AvNRxg3RfaCMAI-1WBIRYzVIWVvf74l02tUkHXaeyqUiEvvWZQLlSQeCciRpb/pub?gid=246664237&single=true&output=tsv',
  aportes: `${base}/binance-data/aportes.json?tmp=${time}`,
  borrowPower: `${base}/binance-data/binance-borrow-power-basic-${account}.json?tmp=${time}`,
};
let APORTES = [];
// const DT_INIT = 20201122;

const FIX_FUCKING_ERROR = false;
const errDate = moment('20211117', 'YYYYMMDD');

const csvJSON = (csv, splitter = ',') => {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(splitter);

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    let line = lines[i];
    line = line.split(splitter);
    if (!line[0].length) continue;
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j].trim()] = line[j];
    }
    result.push(obj);
  }
  return result;
};

const get = async (url) => {
  const { data } = await axios.get(url);
  return data;
};
export const cotaMean = async () => {
  const data = await get(URL.cotaMean);
  const jsonData = csvJSON(data.replace(/"/g, ''), ';').map((e) => {
    const dt = moment(e.dt, 'YYYY-MM-DD');
    const diff = dt.diff(errDate, 'days');
    if (diff > 0 && FIX_FUCKING_ERROR && account === 'qaqeesimon') {
      e.cota = Number(e.cota.replace(/,/g, '.')) + 0.1388584570622065;
      e.cotaAdmFee =
        Number(e.cotaAdmFee.replace(/,/g, '.')) + 0.1388584570622065;
    } else {
      e.cota = Number(e.cota.replace(/,/g, '.'));
      e.cotaAdmFee = Number(e.cotaAdmFee.replace(/,/g, '.'));
    }
    e.date = moment(e.dt, 'YYYY-MM-DD').valueOf();
    return e;
  });
  return jsonData;
};

function getBenchmarkCota(benchmarks, type, dtInit) {
  if (!benchmarks || benchmarks.length === 0) return [];
  let bench = benchmarks.find((e) => e._id === type).dados_diarios;
  bench = bench.datas.map((e, i) => ({
    data: Number(e.replace(/-/g, '')),
    cota: bench.cotas[i],
  }));
  if (dtInit) {
    console.log({ dtInit });
    bench = bench.filter((e) => e.data >= dtInit);
  }
  return bench.map((e) => ({ data: e.data, cota: e.cota / bench[0].cota }));
}

export const performanceTableApi = async (
  daysBefore = 0,
  benchmarkType = 'DOLAR',
  useAdmFee = true
) => {
  const [data, benchmarks] = await Promise.all([
    get(URL.cotaMean),
    benchmarksApi(),
  ]);
  const cotaType = useAdmFee ? 'cotaAdmFee' : 'cota';

  const cota = csvJSON(data.replace(/"/g, ''), ';')
    .map((e) => {
      e.cota = Number(e[cotaType].replace(/,/g, '.')); // Number(e.cota.replace(/,/g, '.'));
      e.date = moment(e.dt, 'YYYY-MM-DD').valueOf();
      e.dt = Number(e.dt.replace(/-/g, ''));
      const dt = moment(e.dt, 'YYYY-MM-DD');
      const diff = dt.diff(errDate, 'days');
      if (diff > 0 && FIX_FUCKING_ERROR) {
        if (diff === 1) {
          e.var = 0;
          e.varWithAdmFee = 0;
        }
        e.cota = +e.cota + 0.1388584570622065;
        e.wallet = +e.wallet + 19878.2;
        e.walletAdjAdmFee = +e.walletAdjAdmFee + 19878.2;
        e.walletAdjusted = +e.walletAdjusted + 19878.2;
      }
      // if (diff >= 0 && diff < 5) console.log(diff, e);
      return e;
    })
    .filter(
      (e) => moment(e.dt, 'YYYYMMDD').diff(moment(), 'days') <= -daysBefore
    );
  const c = cota.map((e) => {
    const dt = moment.unix(e.date / 1000);
    return Object.assign(e, {
      year: Number(dt.format('YYYY')),
      month: Number(dt.format('MM')),
      yearmonth: Number(dt.format('YYYYMM')),
    });
  });

  const yearmonth = c.map((e) => e.yearmonth).filter(onlyUnique);
  const finalCota = yearmonth.map((e, i) => {
    const currYM = c.filter((f) => f.yearmonth === e);
    const prevYM =
      i > 0
        ? c
            .filter((f) => f.yearmonth === yearmonth[i - 1])
            .sort(sortBy('date', false))
        : [{ cota: 1 }];
    const currCotas = [prevYM[0].cota, ...currYM.map((c) => c.cota)];
    const init = prevYM[0].cota;
    const end = currYM.sort(sortBy('date', false))[0].cota;
    const sharpe = toFixed(
      Math.sqrt(365) *
        PortfolioAnalytics.sharpeRatio(
          currCotas,
          currCotas.map((e) => 0.000001)
        )
    );
    return {
      yearmonth: e,
      year: currYM[0].year,
      month: currYM[0].month,
      init,
      end,
      ret: toFixed(((end - init) * 100) / init),
      sharpe,
    };
  });

  /// other table
  const today = new Date();
  const thisYear = {
    type: 'Ano',
    date: Number(today.getFullYear() - 1 + '1231'),
  };
  const last3months = {
    type: '3 M',
    date: Number(
      formatDate(new Date(new Date(today).setMonth(today.getMonth() - 3)))
    ),
  };
  const last6months = {
    type: '6 M',
    date: Number(
      formatDate(new Date(new Date(today).setMonth(today.getMonth() - 6)))
    ),
  };
  const last12months = {
    type: '12 M',
    date: Number(
      formatDate(new Date(new Date(today).setMonth(today.getMonth() - 12)))
    ),
  };
  const last24months = {
    type: '24 M',
    date: Number(
      formatDate(new Date(new Date(today).setMonth(today.getMonth() - 24)))
    ),
  };
  const sinceBeginning = {
    type: 'Desde o início',
    date: 19900101,
  };

  const benchmarkData = getBenchmarkCota(
    benchmarks,
    benchmarkType,
    +moment.unix(cota[0].date / 1000).format('YYYYMMDD')
  );

  const benchmark = { name: benchmarkType, arr: benchmarkData };
  const tableRets = [
    thisYear,
    last3months,
    last6months,
    last12months,
    last24months,
    sinceBeginning,
  ].map((dts) => {
    const ct = cota.filter((c) => c.dt >= dts.date);
    const ctCota = ct.map((e) => Number(e.cota));
    const bmk = benchmark.arr.filter((c) => c.data >= dts.date);
    const bmkCota = bmk.map((e) => Number(e.cota));
    console.log({
      ctCota,
      bmkCota,
    });
    return {
      type: dts.type,
      ret_crypto: ct.length
        ? toFixed((ct.slice(-1)[0].cota / Number(ct[0].cota) - 1) * 100)
        : '-',
      ret_benchmark: bmk.length
        ? toFixed((bmk.slice(-1)[0].cota / Number(bmk[0].cota) - 1) * 100)
        : '-',
      sharpe_crypto: ct.length
        ? toFixed(
            Math.sqrt(365) *
              PortfolioAnalytics.sharpeRatio(
                ctCota,
                ct.map((e) => 0.000001)
              )
          )
        : '-',
      sharpe_benchmark: ct.length
        ? toFixed(
            Math.sqrt(365) *
              PortfolioAnalytics.sharpeRatio(
                bmkCota,
                bmk.map((e) => 0.000001)
              )
          )
        : '-',
      ret_annualized_crypto:
        toFixed(Math.pow(ctCota.slice(-1)[0], 1 / (ctCota.length / 365)) - 1) *
        100,
      ret_annualized_benchmark:
        toFixed(
          Math.pow(bmkCota.slice(-1)[0], 1 / (bmkCota.length / 252)) - 1
        ) * 100,
    };
  });

  return {
    finalCota,
    tableRets,
    dailyCota: cota.map((e) => {
      return {
        cota: Number(e.cota),
        cotaAdmFee: Number(e.cotaAdmFee),
        date: e.date,
        dt: e.dt,
      };
    }),
    lastDay: cota.slice(-1)[0]?.dt || 0,
  };
};

export const walletDaily = async () => {
  const data = await get(URL.walletDaily);
  return data;
};

export const cota = async () => {
  const data = csvJSON(await get(URL.cotaMean), ';');
  return data;
};
export const cotaChartData = async () => {
  const data = await cota();
  const ret = data.map((e) => {
    e.data = Date.parse(getDate(e.data.replace(/-/g, '')));
    return e;
  });
  return ret;
};

export const fundingHistory = async () => {
  const [funding, consolidated] = await Promise.all([
    get(URL.funding),
    walletDaily(),
  ]);
  return Object.keys(funding).map((e) => ({
    dtstr: e,
    dt: Date.parse(getDate(e.replace(/-/g, ''))),
    value: funding[e],
    position: (
      consolidated.find((c) => c.dt === Number(e.replace(/-/g, ''))) || {
        wallet: 0,
      }
    ).wallet,
  }));
};

export const btc = async (type = 'csv') => {
  const data = await get(URL.btc);
  if (type !== 'csv') return data;
  return Object.keys(data).map((e) => ({
    dtstr: e,
    dt: Date.parse(getDate(e.replace(/-/g, ''))),
    price: data[e],
  }));
};
export const wallet = async () => {
  // const txtData = await get(URL.wallet);
  let txtData = await fetch(URL.wallet);
  txtData = await txtData.text();
  const header = 'tot;spot;cross;margin;fut;futUPL;ts;dt;borrowed;delivery\n';
  const data = csvJSON(header + txtData, ';');
  return data;
};

export const borrowPowerApi = async () => {
  try {
    const data = await get(URL.borrowPower);
    return data
      .split('\n')
      .filter((e) => e)
      .map((e) => JSON.parse(e.replace(/,\s*$/, '')));
  } catch (error) {
    console.log(error);
  }
  return [];
};
export const benchmarksApi = async () => {
  // const data = await get(URL.benchmarks);
  return benchmarksFromInfomoney();
};

const benchmarksFromInfomoney = async () => {
  const data = await get(URL.benchmarksInfomoney);
  return data.map((e) => ({
    _id: e.nom_atv
      .replace('Dolar Comercial', 'DOLAR')
      .replace('IBOVESPA', 'IBOV'),
    dados_diarios: {
      cotas: e.variacoes.map((v) => 1 + v.y / 100),
      datas: e.variacoes.map((v) => v.x.split('T')[0]),
    },
  }));
};
/* export const walletDaily = async () => {
  let daily = [];
  let con = await consolidated();
  con.forEach((c) => {
    const currD = daily.find((d) => d && d.dt === c.dt);
    if (!currD) daily.push(c);
    if (currD && new Date(c.date) > new Date(currD.date)) {
      daily = daily.filter((d) => d.dt !== c.dt);
      daily.push(c);
    }
  });
  con = daily;
  return con;
}; */

export const consolidated = async () => {
  let consolidated = await get(URL.consolidated);
  consolidated = consolidated
    .split('\n')
    .filter((e) => e)
    .map((e) => JSON.parse(e.replace(/,\s*$/, '')));
  return consolidated.map((e) => {
    e.dt = Number(e.date.split('T')[0].replace(/-/g, ''));
    return e;
  });
};

export const getAportesApi = async () => {
  if (APORTES.length === 0) {
    try {
      const apt = await get(URL.aportes);
      /* APORTES = apt
        .split('\r\n')
        .map((e) => e.split('\t'))
        .slice(0, -6)
        .filter((e) => e[0] && e[0] !== 'DT')
        .map((e) => {
          e[1] = parseFloat(e[1].replace('.', '').replace(',', '.'));
          e[0] = moment(e[0], 'DD/MM/YYYY').valueOf();
          return e;
        }); */
      APORTES = apt.map((e) => [e.insertTime || 160601400000, e.amount]);
    } catch (error) {
      console.log(error);
    }
  }
  return APORTES;
};
