import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import simonAuth from '../../providers/auth/simonAuth';

import GlobalStyles from '../../styles/GlobalStyles';

import {
  Container,
  Main,
  MiddleBox,
  BoxHead,
  Image,
  Button,
  H1,
} from './styles';

const Base = () => {
  const { logout } = simonAuth();
  const search = useLocation().search;
  const authorized = !(
    new URLSearchParams(search).get('error') === 'unauthorized'
  );
  const body = !authorized ? (
    <>
      <H1>{new URLSearchParams(search).get('error_description')}</H1>
      <p>Your e-mail is not authorized to access the system.</p>
      <br />
      <p>Contact the administrator</p>
    </>
  ) : (
    <>
      <H1>Welcome</H1>
      <p>Log in to continue to Crypto Results.</p>
    </>
  );

  const button = !authorized ? (
    <Button onClick={() => logout()}>Log Out</Button>
  ) : (
    <Link
      to="/report"
      style={{
        textDecoration: 'none',
        display: 'block',
        color: 'var(--menu-text)',
      }}
    >
      <Button type="submit" name="action" value="default">
        Continue
      </Button>
    </Link>
  );
  return (
    <>
      <Container>
        <Main>
          <MiddleBox>
            <BoxHead>
              <Image
                id="prompt-logo-center"
                src="https://crypto.simoncapital.ai/img/simon.png"
                alt="Simon Capital"
              />
              {body}
              {button}
            </BoxHead>
          </MiddleBox>
        </Main>
      </Container>
      <GlobalStyles />
    </>
  );
};

export default Base;
