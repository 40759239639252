import React, { useEffect, useState } from 'react';
import {
  funding as fundingApi,
  consolidated as consolidatedApi,
} from '../../api/last';
import { arrayMax, sortBy } from '../../utils';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Container } from './style';

import Loading from '../Loading';

HighchartsMore(Highcharts);

export default function ChartFundingLast() {
  const [lastFunding, setLastFunding] = useState([]);
  const [consolidated, setcConsolidated] = useState([]);

  useEffect(() => {
    fundingApi()
      .then((data) => setLastFunding(data))
      .catch(console.log);
    consolidatedApi()
      .then((data) => setcConsolidated(data))
      .catch(console.log);
  }, []);
  let funding = [];

  if (lastFunding.length === 0 || consolidated.length === 0) {
    return <Loading />;
  } else {
    funding = lastFunding.map((e) => {
      const tot = lastFunding.reduce((sum, cur) => sum + cur.balanceUSDT, 0);
      e.polarPos = (e.balanceUSDT * 100) / tot;
      return e;
    });
    funding = lastFunding.map((e) => {
      const tot = lastFunding.reduce((sum, cur) => sum + cur.income, 0);
      e.polarFunding = (e.income * 100) / (tot - e.income);
      return e;
    });
    funding = funding.sort(sortBy('polarPos', false));
    // const totalUsdt = funding.reduce((acc, curr) => curr.balanceUSDT + acc, 0);
    const totalFunding = funding.reduce((acc, curr) => curr.income + acc, 0);
    const fundingPct = (totalFunding * 100) / consolidated.netValue;
    const fundingPctAA = (
      100 *
      (Math.pow(1 + fundingPct / 100, 365 * 3) - 1)
    ).toFixed(0);

    const polarPos = funding.map((e) => e.polarPos);
    const polarFunding = funding.map((e) => e.polarFunding);
    const maxValue = Math.ceil(arrayMax([...polarFunding, ...polarPos]));
    const chartOptions = {
      chart: { polar: true, type: 'line' },
      accessibility: { description: '' },
      title: { text: 'Funding vs Position' },
      subtitle: {
        text: `Last: ${fundingPct.toFixed(3)}% (${fundingPctAA}% APY)`,
      },
      pane: { size: '85%' },

      xAxis: {
        categories: funding.map((e) => e.symbol.replace('USDT', '')),
        tickmarkPlacement: 'on',
        lineWidth: 0,
      },

      yAxis: {
        gridLineInterpolation: 'circle',
        lineWidth: 0,
        min: 0,
        max: maxValue,
      },

      tooltip: {
        shared: true,
        pointFormat:
          '<span style="color:{series.color}">{series.name}: <b>{point.y:,.2f}%</b><br/>',
      },

      legend: {
        enabled: false,
      },

      series: [
        {
          name: 'Funding',
          type: 'area',
          color: '#1EACE4',
          data: polarFunding,
          pointPlacement: 'on',
          lineWidth: 0,
        },
        {
          name: 'Position',
          data: polarPos,
          pointPlacement: 'on',
          color: '#314774',
          lineWidth: 1,
        },
      ],

      exporting: { enabled: false },
      credits: false,
    };
    return (
      <Container>
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { width: '100%' } }}
          options={chartOptions}
        />
      </Container>
    );
  }
}
