import React, { useEffect, useState } from 'react';
import { getAportesApi, walletDaily as walletApi } from '../../api/history';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatMoney, getAportes } from '../../utils';
import Loading from '../Loading';
import { Container, Table } from './style';

HCExporting(Highcharts);

function ChartPatrimonio(props) {
  const [wallet, setWallet] = useState([]);
  const [aportes, setAportes] = useState([]);
  const { type, daysBefore } = props;

  useEffect(() => {
    walletApi()
      .then((data) => setWallet(data))
      .catch(console.log);
    getAportesApi()
      .then((data) => setAportes(data))
      .catch(console.log);
  }, []);

  if (wallet.length === 0 || aportes.length === 0) {
    return <Loading height={400} />;
  } else {
    let finalWallet = wallet;
    if (['monthly', 'table'].indexOf(type) >= 0) {
      finalWallet = wallet.filter(
        (e, i) =>
          (i === 0 ||
            (e.dt + '').slice(-2) === '01' ||
            e.dt ===
              moment().subtract(daysBefore, 'days').format('YYYY-MM-DD')) &&
          moment(e.dt, 'YYYYMMDD').diff(moment(), 'days') <= -daysBefore
      );
    }
    if (type === 'table' && finalWallet.length) {
      const lastWallet = finalWallet.slice(-1)[0];
      const valorAplicado = getAportes(
        moment(lastWallet.dt, 'YYYYMMDD').endOf('day').valueOf(),
        aportes
      );
      return (
        <Container>
          <Table>
            <tbody>
              <tr>
                <td>Patrimônio Líquido:</td>
                <td>{formatMoney(lastWallet.wallet)} USDT</td>
              </tr>
              <tr>
                <td>Valor Aplicado:</td>
                <td>{formatMoney(valorAplicado)} USDT</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      );
    }

    const chartOptions = {
      chart: {
        plotBorderWidth: null,
        type: 'spline',
        zoomType: 'x',
        events: {
          beforePrint: function () {
            this.oldhasUserSize = this.hasUserSize;
            this.resetParams = [this.chartWidth, this.chartHeight, false];
            this.setSize(800, 400, false);
          },
          afterPrint: function () {
            this.setSize.apply(this, this.resetParams);
            this.hasUserSize = this.oldhasUserSize;
          },
        },
      },
      xAxis: { type: 'datetime', title: { text: '' } },
      yAxis: { title: { text: '' } },
      legend: {
        verticalAlign: 'top',
        enabled: true,
      },
      tooltip: {
        shared: true,
        crosshairs: [true, false],
        valueDecimals: 2,
      },
      plotOptions: { series: { marker: { enabled: false } } },
      title: { text: '' },
      series: [
        {
          name: 'Patrimônio',
          data: finalWallet.map((e) => [
            // Date.parse(getDate(e.date.split('T')[0].replace(/-/g, ''), 1)),
            moment(e.dt, 'YYYYMMDD').valueOf(),
            e.wallet,
          ]),
          color: '#314774',
        },
        {
          name: 'Valor Aplicado',
          data: finalWallet.map((e) => {
            const dt = moment(e.dt, 'YYYYMMDD').valueOf();
            // Date.parse(getDate(e.date.split('T')[0].replace(/-/g, ''), 1));
            return [
              dt,
              getAportes(
                moment(e.dt, 'YYYYMMDD').endOf('day').valueOf(),
                aportes
              ),
            ];
          }),
          color: '#8CAEAF',
        },
      ],
      exporting: { enabled: true },
      credits: false,
    };

    return (
      <Container>
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { width: '100%' } }}
          options={chartOptions}
        />
      </Container>
    );
  }
}

ChartPatrimonio.propTypes = {
  type: PropTypes.string,
  daysBefore: PropTypes.number,
};

export default ChartPatrimonio;
