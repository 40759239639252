import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const TableBenchmark = styled.table`
  border-top: solid 2px #ffffff !important;
  line-height: 1 !important;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;

  th {
    display: table-cell;
    border-color: inherit;
    text-align: center;
    border-bottom-width: 2px;
    font-weight: 700;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    padding: 7px;
    padding-bottom: 10px;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
  td {
    padding: 7px;
  }
  tbody tr:nth-child(1) td {
    font-size: 12px;
    color: #919191;
    border-bottom: 1px solid #dee2e6;
  }
  tbody tr:nth-child(even) td {
    border-bottom: 1px solid #dee2e6;
  }
  tbody td:nth-child(even) {
    border-left: dashed 1px #e2e2e2;
  }
  tbody td:last-child {
    border-left: none;
  }
  tbody tr:nth-child(2) td:first-child {
    color: #274583;
  }
  tbody tr {
    color: rgb(30, 172, 228);
  }
  tbody tr:nth-child(3) {
    color: #707070;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-weight: 400;
  border-spacing: 0px;

  tr > td:last-child {
    font-weight: 700;
  }
  tbody tr:nth-child(4n + 1) td {
    background-color: white;
  }
  tbody tr:nth-child(4n + 2) td {
    background-color: white;
    border-bottom: 1px solid #cecece;
  }
  tbody tr:nth-child(4n + 3) td {
    background-color: #f5f5f5;
  }
  tbody tr:nth-child(4n + 4) td {
    background-color: #f5f5f5;
    //border-bottom: 2px solid #cecece;
  }

  thead td {
    border-bottom: solid rgb(30, 172, 228);
    text-align: center;
    font-size: 13px;
    font-weight: 700;
  }
  td {
    width: 136px;
    padding: 7px;
    text-align: center;
    font-size: 14px;
  }
`;

export const Details = styled(Typography)`
  color: #0d2e72;
  padding-bottom: 0px;
`;
export const UL = styled.ul`
  list-style-type: none;
`;
