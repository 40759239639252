import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import ContextProvider from './context/GlobalContext';
import Component404 from './pages/404';
import Base from './pages/base';
import Lamina from './pages/lamina';
import Monitor from './pages/monitor';
import Report from './pages/report';
import reportDetailed from './pages/reportDetailed';
import Auth0ProviderWithHistory from './providers/auth/auth';
import ProtectedRoute from './providers/auth/protected-route';

export const history = createBrowserHistory();

ReactDOM.render(
  <ContextProvider>
    <Router history={history}>
      <Auth0ProviderWithHistory>
        <Switch>
          <Route path="/" exact={true} component={Base} />
          <Route path="/index.html" exact={true} component={Base} />
          <ProtectedRoute path="/monitor" component={Monitor} />
          <ProtectedRoute path="/report" component={Report} />
          <ProtectedRoute path="/reportDetailed" component={reportDetailed} />
          <ProtectedRoute path="/lamina" component={Lamina} />
          <Route path="*" component={Component404} />
        </Switch>
      </Auth0ProviderWithHistory>
    </Router>
  </ContextProvider>,
  document.getElementById('root')
);
