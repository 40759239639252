import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const TransactionContext = createContext({});

const ContextProvider = ({ children }) => {
  const [data, setData] = useState({});

  return (
    <TransactionContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ContextProvider;
