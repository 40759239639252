import React, { useEffect, useState } from 'react';
import { fundingHistory as fundingApi } from '../../api/history';
// import { getDate } from '../../utils';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { Container } from './style';

import indicators from 'highcharts/indicators/indicators';
import ema from 'highcharts/indicators/ema';

import Loading from '../Loading';

indicators(Highcharts);
ema(Highcharts);

export default function ChartFundingHistory() {
  const [fundingHistory, setFundingHistory] = useState([]);

  useEffect(() => {
    fundingApi()
      .then((data) => setFundingHistory(data))
      .catch(console.log);
  }, []);

  if (fundingHistory.length === 0) {
    return <Loading />;
  } else {
    // const timezone = 3 * 60 * 60 * 1000;
    const chartOptions = {
      navigator: { enabled: false },
      rangeSelector: {
        buttons: [
          {
            type: 'week',
            count: 1,
            text: 'Week',
          },
          {
            type: 'month',
            count: 1,
            text: 'Month',
          },
          {
            type: 'all',
            text: 'All',
          },
          {
            type: 'ytd',
            text: 'YTD',
          },
        ],
        buttonTheme: { width: 60 },
        selected: 1,
        inputEnabled: false,
      },
      title: { text: 'Funding' },
      subtitle: { text: '' },
      xAxis: { type: 'datetime', title: { text: '' } },
      yAxis: [
        { title: { text: 'USDT' }, opposite: true },
        { title: { text: '%' }, opposite: false, linkedTo: 0 },
      ],
      plotOptions: {
        series: {
          dataGrouping: { enabled: false },
          marker: { enabled: false },
        },
      },
      legend: {
        enabled: false,
      },

      series: [
        {
          type: 'column',
          name: 'Funding',
          id: 'funding',
          data: fundingHistory.map((e) => [
            e.dt,
            Number(parseFloat(e.value).toFixed(2)),
          ]),
          color: '#314774',
          lineWidth: 1,
          yAxis: 0,
        },
        {
          type: 'line',
          name: 'Funding APY',
          id: 'fundingAPY ',
          data: fundingHistory.map((e) => {
            const fund = Number(e.value);
            const fundingPct = e.position > 0 ? fund / e.position : 0;
            const fundPctAPY = (
              100 *
              (Math.pow(1 + fundingPct, 365) - 1)
            ).toFixed(2);
            return [e.dt, Number(fundPctAPY)];
          }),
          color: '#314774',
          lineWidth: 1,
          yAxis: 1,
          // visible: false,
        },

        /* {
          type: 'ema',
          name: 'MM30',
          linkedTo: 'funding',
          zIndex: 1,
          lineWidth: 2,
          opacity: 1,
          yAxis: 0,
          color: '#1EACE4',
          params: {
            period: 30, // 8horas
          },
        }, */
        {
          type: 'ema',
          name: 'Funding APY  MM30',
          linkedTo: 'fundingAPY ',
          zIndex: 1,
          lineWidth: 2,
          opacity: 1,
          yAxis: 0,
          color: '#1EACE4',
          params: {
            period: 30, // 8horas
          },
        },
      ],
      tooltip: {
        enabled: true,
        split: true,
        distance: 50,
        padding: 10,
        formatter: function () {
          const splitFormatter = function (p) {
            if (typeof p === 'undefined') return '';
            if (p.series.name === 'Funding APY') return '';
            return (
              '<strong>' +
              p.series.name +
              '</strong>' +
              '<br /><strong>' +
              (p.series.name.indexOf('APY ') > 0 ? '' : '$ ') +
              parseFloat(p.y).toFixed(2) +
              (p.series.name.indexOf('APY ') > 0 ? ' %' : '')
            );
          };
          return [
            Highcharts.dateFormat('%d/%m/%Y %H:%M', this.x),
            splitFormatter(this.points[0]),
            splitFormatter(this.points[1]),
            splitFormatter(this.points[2]),
            splitFormatter(this.points[3]),
          ];
        },
      },
      scrollbar: { enabled: false },
      exporting: { enabled: false },
      credits: {
        enabled: false,
      },
    };
    return (
      <Container>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          containerProps={{ style: { width: '100%' } }}
          options={chartOptions}
        />
      </Container>
    );
  }
}
