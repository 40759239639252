import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerWithSize = styled.div`
  height: ${(props) => props.height || 0}px;
  border-radius: 25px;
  background: rgb(234, 234, 234);
  width: 100%;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
`;
