// import { getAportesApi } from './api/history';

export const arrayMax = (arr) => {
  return arr.reduce((max, val) => (max > val ? max : val));
};

export const sumArray = (arr) => {
  return arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
};
export const sumByKey = (arr, key) => {
  return sumArray(arr.map((e) => e[key] || 0));
};

export const sortBy = (property, ascending = true) => {
  return (a, b) => {
    if (a[property] === b[property]) return 0;
    return (ascending ? -1 : 1) * (a[property] > b[property] ? -1 : 1);
  };
};
export const toFixed = (number, decimals = 2) => {
  return Number(number.toFixed(2));
};
export const numberFormat = (number, decimals = 2) => {
  return Number(number)
    .toFixed(decimals)
    .replace(/\./g, ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const getDate = (dt) => {
  const dateString = dt + '';
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const getAportes = (dt, aportes) => {
  try {
    // const dtjs = new Date(dt);
    // const aportes = await getAportesApi();
    let summary = 0;
    for (const p in aportes) {
      if (dt >= aportes[p][0]) {
        summary = summary + aportes[p][1];
      }
    }
    return summary;
  } catch (error) {
    return 0;
  }
};

export const groupBySum = (array, groupKey, sumKey) => {
  const result = [];
  array.reduce(function (res, value) {
    if (!res[value[groupKey]]) {
      res[value[groupKey]] = { [groupKey]: value[groupKey], [sumKey]: 0 };
      result.push(res[value[groupKey]]);
    }
    res[value[groupKey]][sumKey] += value[sumKey];
    return res;
  }, {});
  return result;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('');
};

export const formatMoney = (value) => {
  return Number(value)
    .toFixed(2)
    .replace(/\./g, ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const getTotalAportes = (dt, aportes) => {
  try {
    let summary = 0;
    for (const p in aportes) {
      if (dt >= aportes[p][0]) {
        summary = summary + aportes[p][1];
      }
    }
    return summary;
  } catch (error) {
    return 0;
  }
};
