import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { Link as ReactLink } from 'react-router-dom';

export const Container = styled.div`
  flex-grow: 1;
  width: 100vw;
  justify-content: 'space-between';
`;
export const Left = styled.div``;

export const Right = styled.div``;

export const Link = styled(ReactLink)`
  color: 'red';
  text-decoration: 'none';
  display: 'block';
  a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
  }
`;

export const StyledTypography = styled(Typography)`
  flex-grow: 1;
`;

export const StyledAppBar = styled(AppBar)`
  background-color: var(--simon-blue) !important;
`;

export const Logo = styled.img`
  height: 25px;
  align-self: center;
`;
