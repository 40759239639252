import React, { useEffect, useState } from 'react';
import ChartPatrimonio from '../../components/Charts/patrimonio';
import ChartPerformBenchmark from '../../components/Charts/performBenchmark';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import PerformanceTable from '../../components/PerformanceTable';
import simonAuth from '../../providers/auth/simonAuth';
import GlobalStyles from '../../styles/GlobalStyles';
import { noPower } from '../noPower';

import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import {
  accounts as accountsApi,
  position as positionApi,
} from '../../api/last';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Separator = styled(Typography)`
  border-bottom: solid 1.4px #0d2e72;
`;

const Report = () => {
  const [position, setPosition] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const { user, isLoading, logout } = simonAuth();
  const userHasNoPower = !isLoading && !user.simonAdm;
  const [useAdmFee, setUseAdmFee] = useState(false);

  function handleButton(e) {
    e.preventDefault();
    setUseAdmFee(!useAdmFee);
  }
  useEffect(() => {
    if (userHasNoPower) document.body.style.backgroundColor = '#eaf2fe';
    accountsApi()
      .then((data) => setAccounts(data))
      .catch(console.log);
    positionApi()
      .then((data) =>
        setPosition(
          data.map((e) => {
            e.usdt = Number(e.usdt);
            e.borrowed = Number(e.borrowed);

            return e;
          })
        )
      )
      .catch(console.log);
  }, [userHasNoPower]);

  if (userHasNoPower) {
    return noPower({ user, logout });
  }
  if (accounts.length === 0 || position.length === 0) return <Loading />;

  return (
    <>
      <Header />
      <Container>
        <Box m={'auto'} maxWidth={1024} p={1} mt={2}>
          <FormControlLabel
            control={
              <Switch
                checked={useAdmFee}
                onChange={handleButton}
                name="checkedB"
                color="primary"
              />
            }
            label="Adm Fee"
          />
          <Grid container spacing={3} mt={10} pt={30}>
            <Grid item xs={12}>
              <Separator>PERFORMANCE HISTÓRICA</Separator>
            </Grid>
            <Grid item xs={12}>
              <PerformanceTable useAdmFee={useAdmFee} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Separator>GRÁFICO DE PERFORMANCE</Separator>
              <Box component={Paper} mt={3}>
                <ChartPerformBenchmark useAdmFee={useAdmFee} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Separator>PATRIMÔNIO</Separator>
              <Box component={Paper} mt={3}>
                <ChartPatrimonio />
              </Box>
            </Grid>
            {/** 
            <Grid item xs={12} md={12}>
              <Separator>PORTFÓLIO</Separator>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <ReportTable
                header={['Composição', '%']}
                data={groupedPosition.sort(sortBy('usdt', false)).map((e) => ({
                  [e.symbol]: e.pct + '%',
                }))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12} md={12}>
                <Grid item xs={12} md={12}>
                  <Separator>CONCENTRAÇÃO DA CARTEIRA</Separator>
                </Grid>
                <Box component={Paper} mt={3}>
                  <PortfolioConcentration concentration={concentration} />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box component={Paper} mt={3}>
                  <ReportTable
                    header={['Liquidez', '% Patrimônio']}
                    data={liquidez}
                  />
                </Box>
                <Box component={Paper} mt={3}>
                  <ReportTable
                    header={['PL', 'USD']}
                    data={[
                      { 'PL Total Administrado	': numberFormat(positionTot, 2) },
                    ]}
                  />
                </Box>
              </Grid>
               
            </Grid>
            **/}
          </Grid>
        </Box>
      </Container>

      <GlobalStyles />
    </>
  );
};

export default Report;
