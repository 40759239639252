import React, { useEffect, useState } from 'react';
import { benchmarksApi, cotaMean as cotaApi } from '../../api/history';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getDate } from '../../utils';
import Loading from '../Loading';
import { Container } from './style';

HCExporting(Highcharts);

function getBenchmarkCota(benchmarks, type, dtInit) {
  if (!benchmarks || benchmarks.length === 0) return [];
  let bench = benchmarks.find((e) => e._id === type).dados_diarios;
  bench = bench.datas.map((e, i) => ({
    data: Number(e.replace(/-/g, '')),
    cota: bench.cotas[i],
  }));
  if (dtInit) {
    bench = bench.filter((e) => e.data >= dtInit);
  }
  return bench.map((e) => ({ data: e.data, cota: e.cota / bench[0].cota }));
}

function ChartPerformBenchmark(props) {
  const [cota, setCota] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const { type, daysBefore, useAdmFee } = props;
  const cotaType = useAdmFee ? 'cotaAdmFee' : 'cota';

  useEffect(() => {
    cotaApi()
      .then((data) => setCota(data))
      .catch(console.log);

    benchmarksApi()
      .then((data) => setBenchmarks(data))
      .catch(console.log);
  }, []);

  if (cota.length === 0 || benchmarks.length === 0) {
    return <Loading height={400} />;
  } else {
    const finalCota =
      type === 'monthly'
        ? cota
            .filter(
              (e, i) =>
                (i === 0 ||
                  e.dt ===
                    moment(e.dt, 'YYYY-MM-DD')
                      .endOf('month')
                      .format('YYYY-MM-DD') ||
                  // e.dt.slice(-2) === '01' ||
                  e.dt.slice(-2) === '15' ||
                  e.dt ===
                    moment()
                      .subtract(daysBefore, 'days')
                      .format('YYYY-MM-DD')) &&
                moment(e.dt, 'YYYY-MM-DD').diff(moment(), 'days') <= -daysBefore
            )
            .map((e) =>
              Object.assign(
                { dtMoment: moment(e.dt, 'YYYY-MM-DD').diff(moment(), 'days') },
                e
              )
            )
        : cota;

    const cotaDays = finalCota.map((e) => Number(e.dt.replace(/-/g, '')));
    const DT_INIT = cotaDays[0];

    // const benchmark = { name: 'Dolar', arr: dolar };
    const benchmark2 = {
      name: 'CDI',
      arr: getBenchmarkCota(benchmarks, 'CDI', DT_INIT),
    };
    const ibov = getBenchmarkCota(benchmarks, 'IBOV', DT_INIT);
    const dolar = getBenchmarkCota(benchmarks, 'DOLAR', DT_INIT);

    const lastDay = cotaDays.slice(-1)[0];
    const chartOptions = {
      chart: {
        plotBorderWidth: null,
        type: 'spline',
        zoomType: 'x',
        events: {
          beforePrint: function () {
            this.oldhasUserSize = this.hasUserSize;
            this.resetParams = [this.chartWidth, this.chartHeight, false];
            this.setSize(800, 400, false);
          },
          afterPrint: function () {
            this.setSize.apply(this, this.resetParams);
            this.hasUserSize = this.oldhasUserSize;
          },
        },
      },
      xAxis: { type: 'datetime', title: { text: '' } },
      yAxis: { type: 'logarithmic', title: { text: '' } },
      legend: {
        verticalAlign: 'top',
        enabled: true,
      },
      tooltip: {
        shared: true,
        crosshairs: [true, false],
        valueDecimals: 2,
      },
      plotOptions: { series: { marker: { enabled: false } } },
      title: { text: '' },
      series: [
        /* {
          name: 'Simon Crypto',
          data: cota.map((e) => [e.date, e.cota * 100]),
          color: '#314774',
        }, */
        {
          name: 'Simon Crypto',
          data: finalCota.map((e) => [e.date, e[cotaType] * 100]),
          color: '#314774',
        },
        {
          name: benchmark2.name,
          data: benchmark2.arr
            .filter(
              (e) => e.data <= lastDay
              // cotaDays.indexOf(e.data) >= 0 || (e.data + '').slice(-2) === '15'
            )
            .map((e) => [Date.parse(getDate(e.data)), e.cota * 100]),
          color: '#B1CCDC',
          dashStyle: 'dash',
        },
        {
          name: 'Ibovespa',
          data: [
            [Date.parse(getDate(DT_INIT)), 100],
            ...ibov
              .filter(
                (e) => e.data <= lastDay
                // cotaDays.indexOf(e.data) >= 0 || (e.data + '').slice(-2) === '15'
              )
              .map((e) => [Date.parse(getDate(e.data)), e.cota * 100]),
          ],
          color: '#8CAEAF',
        },
        {
          name: 'Dolar',
          data: [
            [Date.parse(getDate(DT_INIT)), 100],
            ...dolar
              .filter(
                (e) => e.data <= lastDay
                // cotaDays.indexOf(e.data) >= 0 || (e.data + '').slice(-2) === '15'
              )
              .map((e) => [Date.parse(getDate(e.data)), e.cota * 100]),
          ],
          color: '#afac71',
        },
      ],
      exporting: { enabled: true },
      credits: false,
    };

    return (
      <Container>
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { width: '100%' } }}
          options={chartOptions}
        />
      </Container>
    );
  }
}

ChartPerformBenchmark.propTypes = {
  type: PropTypes.string,
  daysBefore: PropTypes.number,
  useAdmFee: PropTypes.bool,
};

export default ChartPerformBenchmark;
