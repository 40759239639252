import React, { useEffect, useState } from 'react';
// import GlobalStyles from '../../styles/GlobalStyles';
import { Box, Paper, TableContainer } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { performanceTableApi } from '../../api/history';
import { onlyUnique } from '../../utils';
import Loading from '../Loading';
import { Details, Table, TableBenchmark, UL } from './styles';

import PortfolioAnalytics from 'portfolio-analytics';

function getData(finalCota, year, month, type) {
  if (!finalCota) return '-';
  const ret = finalCota.find((e) => e.year === year && e.month === month);
  if (!ret) return '-';
  return ret[type] || '-';
}

export default function PerformanceTable(props) {
  const [performanceTable, setPerformanceTable] = useState({});
  const { daysBefore, useAdmFee = true, hideInfo } = props;
  const cotaType = useAdmFee ? 'cotaAdmFee' : 'cota';
  useEffect(() => {
    performanceTableApi(daysBefore, 'DOLAR', useAdmFee)
      .then((data) => setPerformanceTable(data))
      .catch(console.log);
  }, [daysBefore, useAdmFee]);

  if (!('finalCota' in performanceTable)) {
    return <Loading height={200} />;
  }

  let years = [];
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  if ('finalCota' in performanceTable) {
    years = performanceTable.finalCota.map((e) => e.year).filter(onlyUnique);
    //
  }

  const tableRets = performanceTable.tableRets;
  const infos = [
    `Dados até: ${moment(performanceTable.lastDay, 'YYYYMMDD').format(
      'DD/MM/YYYY'
    )}`,
    `Taxa de adm: ${useAdmFee ? 3 : 0}%`,
  ].map((e, i) => (
    <li key={i}>
      <Details variant="caption">{e}</Details>
    </li>
  ));
  return (
    <>
      {!hideInfo && <UL>{infos}</UL>}
      <TableContainer component={Paper}>
        <Table>
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>Jan</td>
              <td>Fev</td>
              <td>Mar</td>
              <td>Abr</td>
              <td>Mai</td>
              <td>Jun</td>
              <td>Jul</td>
              <td>Ago</td>
              <td>Set</td>
              <td>Out</td>
              <td>Nov</td>
              <td>Dez</td>
              <td>Anual</td>
            </tr>
          </thead>
          <tbody>
            {years.map((year, i) => {
              const cotYear = performanceTable.finalCota.filter(
                (e) => e.year === year
              );
              const retYear =
                (cotYear.slice(-1)[0].end / cotYear[0].init - 1) * 100;
              const currCotas = performanceTable.dailyCota
                .filter((e) => Number((e.dt + '').substring(0, 4)) === year)
                .map((e) => e[cotaType]);

              const sharpeYear =
                Math.sqrt(365) *
                PortfolioAnalytics.sharpeRatio(
                  currCotas,
                  currCotas.map((e) => 0.000001)
                );
              return (
                <React.Fragment key={i + year + 'ret'}>
                  <tr>
                    <td>{year}</td>
                    <td>
                      Retorno<small>%</small>
                    </td>
                    {months.map((month) => (
                      <td key={year + '-' + month + 'ret'}>
                        {getData(
                          performanceTable.finalCota,
                          year,
                          month,
                          'ret'
                        )}
                      </td>
                    ))}
                    <td>{retYear.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>{year}</td>
                    <td>Sharpe</td>
                    {months.map((month) => (
                      <td key={year + '-' + month + 'sharpe'}>
                        {getData(
                          performanceTable.finalCota,
                          year,
                          month,
                          'sharpe'
                        )}
                      </td>
                    ))}
                    <td>{sharpeYear.toFixed(2)}</td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      <Box pt={3}>
        <TableContainer component={Paper}>
          <TableBenchmark>
            <thead>
              <tr>
                <th></th>
                <th colSpan="2">Ano</th>
                <th colSpan="2">3 M</th>
                <th colSpan="2">6 M</th>
                <th colSpan="2">12 M</th>
                <th colSpan="2">24 M</th>
                <th colSpan="3">Desde o início</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>Retorno</td>
                <td>Sharpe</td>
                <td>Retorno</td>
                <td>Sharpe</td>
                <td>Retorno</td>
                <td>Sharpe</td>
                <td>Retorno</td>
                <td>Sharpe</td>
                <td>Retorno</td>
                <td>Sharpe</td>
                <td>Retorno</td>
                <td>Anualizado</td>
                <td>Sharpe</td>
              </tr>
              <tr>
                <td>
                  <b>Simon Crypto¹</b>
                </td>

                {tableRets.map((ret, i) =>
                  ret.type !== 'Desde o início' ? (
                    <React.Fragment key={i + 'crypto'}>
                      <td>
                        <span>{ret.ret_crypto}%</span>
                      </td>
                      <td>
                        <span>{ret.sharpe_crypto}</span>
                      </td>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={i + 'crypto'}>
                      <td>
                        <span>{ret.ret_crypto}%</span>
                      </td>
                      <td>
                        <span>{ret.ret_annualized_crypto.toFixed(0)}%</span>
                      </td>
                      <td>
                        <span>{ret.sharpe_crypto}</span>
                      </td>
                    </React.Fragment>
                  )
                )}
              </tr>
              <tr>
                <td>
                  <b>
                    <span>Dolar</span>*
                  </b>
                </td>
                {tableRets.map((ret, i) =>
                  ret.type !== 'Desde o início' ? (
                    <React.Fragment key={i + 'dol'}>
                      <td>
                        <span>{ret.ret_benchmark}%</span>
                      </td>
                      <td>
                        <span>{ret.sharpe_benchmark}</span>
                      </td>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={i + 'dol'}>
                      <td>
                        <span>{ret.ret_benchmark}%</span>
                      </td>
                      <td>
                        <span>{ret.ret_annualized_benchmark.toFixed(0)}%</span>
                      </td>
                      <td>
                        <span>{ret.sharpe_benchmark}</span>
                      </td>
                    </React.Fragment>
                  )
                )}
              </tr>
            </tbody>
          </TableBenchmark>
        </TableContainer>
      </Box>
    </>
  );
}

PerformanceTable.propTypes = {
  daysBefore: PropTypes.number,
  useAdmFee: PropTypes.bool,
  hideInfo: PropTypes.bool,
};
