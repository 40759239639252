import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, ContainerWithSize } from './styles';

function CircularIndeterminate(props) {
  const { height } = props;

  if (height) {
    return (
      <ContainerWithSize height={height}>
        <CircularProgress
          style={{ marginLeft: '50%', marginTop: height / 2 - 20 + 'px' }}
        />
      </ContainerWithSize>
    );
  }
  return (
    <Container>
      <CircularProgress style={{ marginLeft: '50%' }} />
    </Container>
  );
}

CircularIndeterminate.displayName = 'Loading';

export default CircularIndeterminate;

CircularIndeterminate.propTypes = {
  height: PropTypes.number,
};
