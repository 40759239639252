import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Loading from '../Loading';
import { accounts } from '../../api/last';
import { Card, CardContent, Grid, Typography, Box } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Remove';
import { CustomDialog } from '../Modal';

const styles = (theme) => ({
  datePosition: { 'text-align': 'center' },
  withIcon: {
    display: 'inline-flex',
    'align-items': 'center',
  },
});

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, modalValue: '' };
  }

  handleDialogOpen = (e, diff) => {
    console.log(e.target.nodeName);
    if (e.target.nodeName !== 'SPAN')
      this.setState({ isOpen: true, modalValue: diff.toFixed(2) });
  };

  handleDialogClose = (e) => {
    // e.preventDefault();

    // console.log(e, 'close');
    this.setState({ isOpen: false, modalValue: '' });
  };

  render() {
    const val = Number(this.props.val) || 0;
    const diff = Number(this.props.diff) || 0;
    const type = this.props.type;
    // console.log(type, diff);

    let arrow = <RemoveIcon />;
    if (diff < 0) arrow = <ArrowDropDownIcon color="error" />;
    if (diff > 0)
      arrow = <ArrowDropUpIcon style={{ color: 'var(--simon-green)' }} />;
    return (
      <>
        <Card
          style={{
            height: '100%',
          }}
          onClick={(event) => {
            this.handleDialogOpen(event, diff);
          }}
        >
          <CardContent>
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="subtitle2"
                    >
                      {type}
                    </Typography>
                  </Grid>
                  <Grid item>{arrow}</Grid>
                </Grid>
              </Box>
              <Typography color="textPrimary" variant="body2">
                {val.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
              </Typography>
            </Grid>
            <CustomDialog
              isOpen={this.state.isOpen}
              handleClose={(e) => {
                this.handleDialogClose(e);
              }}
              title={type}
              subtitle="Difference between the previous value:"
            >
              <h1>{this.state.modalValue}</h1>
            </CustomDialog>
          </CardContent>
        </Card>
      </>
    );
  }
}

class Accounts extends React.Component {
  state = {
    loading: true,
    accounts: null,
  };

  async componentDidMount() {
    const response = await accounts();
    this.setState({ accounts: response, loading: false });
  }

  render() {
    if (this.state.loading) return <Loading />;
    const { classes } = this.props;
    const acc = this.state.accounts;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} className={classes.datePosition}>
          <Typography variant="caption">
            <Moment format="DD/MM/DD @ HH:mm">{acc.date}</Moment>
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Account type="Wallet" val={acc.netValue} diff={acc.netValueDiff} />
        </Grid>

        <Grid item xs={6} md={4}>
          <Account
            type="Fiat/Spot"
            val={acc.fiatSpot}
            diff={acc.fiatSpotDiff}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Account
            type="Margin"
            val={acc.isolatedMargin}
            diff={acc.isolatedMarginDiff}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Account type="Earn" val={acc.earn} diff={acc.earnDiff} />
        </Grid>
        <Grid item xs={6} md={4}>
          <Account
            type="USD Fut."
            val={acc.usdFutures}
            diff={acc.usdFuturesDiff}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Account
            type="COIN Fut."
            val={acc.coinFutures}
            diff={acc.coinFuturesDiff}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Account type="Borrowed" val={acc.borrowed} diff={acc.borrowedDiff} />
        </Grid>
      </Grid>
    );
  }
}

Account.propTypes = {
  type: PropTypes.string,
  val: PropTypes.number,
  diff: PropTypes.number,
  showDate: PropTypes.string,
};
Accounts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Accounts);
