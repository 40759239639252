import axios from 'axios';
import moment from 'moment';
const base = 'https://data.simoncapital.ai';
const time = moment().format('YYYYMMDDHHMM');
const account = process.env.REACT_APP_ACC;

const URL = {
  funding: `${base}/binance-data/binance-funding-last-${account}.json?time=${time}`,
  margin: `${base}/binance-data/binance-lastmargins-${account}.json?time=${time}`,
  // position: `${base}/position.php`,
  lastPosition: `${base}/last_position.json`,
  consolidatedDiff: `${base}/binance-data/binance-position-consolidated-${account}.json?time=${time}`,
  consolidated: `${base}/binance-data//binance-position-consolidated-last-${account}.json?time=${time}`,
  borrowPower: `${base}/binance-data/binance-borrow-power-${account}-last.json?tmp=${time}`,
};

Object.entries(URL).forEach(([key, val]) => (URL[key] = val + '?time=' + time));

const get = async (url) => {
  const { data } = await axios.get(url);
  return data;
};
export const funding = async () => {
  return get(URL.funding);
};
export const margin = async () => {
  return get(URL.margin);
};
export const consolidated = async () => {
  return get(URL.consolidated);
};

export const accounts = async () => {
  let [consolidated, last] = await Promise.all([
    get(URL.consolidatedDiff),
    get(URL.consolidated),
  ]);
  consolidated = (consolidated + '').replace(/\r?\n|\r/g, '');
  consolidated =
    (consolidated + '').slice(-1) === ','
      ? consolidated.slice(0, -1)
      : consolidated;
  consolidated = JSON.parse(`[${consolidated}]`);
  consolidated.push(last);
  const ret =
    consolidated && consolidated.length ? consolidated.slice(-2) : null;
  const final = Object.assign({}, ret[1]);
  Object.keys(ret[0]).forEach((e) => {
    const key = e + 'Diff';
    const newObj = {};

    newObj[key] = ret[1][e] - ret[0][e];
    if (newObj[key]) Object.assign(final, newObj);
  });

  return final;
};

export const borrowPowerApi = async () => {
  try {
    const data = await get(URL.borrowPower);
    return data;
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const position = async (transposed = false) => {
  try {
    const data = await get(URL.lastPosition);
    // console.log(data, moment(data[0].date).format('YYYY-MM-DD @ hh:mm'));
    const response = data.map((e, i) => ({
      id: i + 1,
      date: moment(e.date).format('YYYY-MM-DD @ hh:mm'),
      symbol: e.symbol,
      balance: e.balance,
      usdt: e.balanceUSDT,
      liquidateRate: e.liquidateRate || '',
      type: e.type.toUpperCase(),
      borrowed: parseFloat(e.borrowed) || 0,
    }));
    // const { data: response } = await get(URL.position);
    if (!transposed) return response;
    const symbols = response
      .map((e) => e.symbol)
      .filter((value, index, self) => self.indexOf(value) === index);
    const dataTable = symbols
      .map((s) => {
        const currData = response.filter((d) => d.symbol === s);
        const diff = currData.reduce((acc, curr) => {
          return Number(curr.usdt) + acc;
        }, 0);
        return {
          symbol: s,
          diff: Number(diff.toFixed(2)),
          futMargin:
            currData.find((f) => f.type === 'FUTURES' || f.type === 'DELIVERY')
              ?.liquidateRate || 0,
          isoMargin:
            currData.find((f) => f.type !== 'FUTURES')?.liquidateRate || 0,
          type: currData
            .map((e) => e.type)
            .join('/')
            .replace('FUTURES', 'FUT')
            .replace('MARGIN', 'SPT')
            .replace('ISOLATED', 'ISO')
            .replace('DELIVERY', 'DLV')
            .replace('DLV_SPT', 'DLV_MRG'),
          borrowed: currData
            .reduce((acc, curr) => {
              return Number(curr.borrowed) + acc;
            }, 0)
            .toFixed(2),
          long: currData.find((l) => l.usdt > 0)?.usdt || 0,
          short: currData.find((l) => l.usdt < 0)?.usdt || 0,
        };
      })
      .filter((e) => ['BNB', 'USDT', 'USDC', 'BUSD'].indexOf(e.symbol) === -1);
    return dataTable;
  } catch (error) {
    console.log(error);
    return [];
  }
};
