import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import SimonLogo from '../images/simon-logo.png';
import simonAuth from '../providers/auth/simonAuth';

const Logo = styled.img`
  height: 45px;
  align-self: center;
`;

const Container = styled.div`
  width: 350px;
  max-width: calc(100vw - 50px);
  height: 150px;
  margin-left: 50px;
  margin-top: 50px;
`;

const Line = styled.p`
  font-family: 'Roboto', sans-serif;
`;
const ImageContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: url('/images/robot.png');
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  background-size: clamp(300px, 80%, 500px) auto;
  width: 100%;
  height: 100%;
`;

export const noPower = (props) => (
  <>
    <Container>
      <Logo alt="Simon" src={SimonLogo} />
      <Line>
        <b>401.</b> Unauthorized Error
      </Line>
      <Line>You have no power here!</Line>
      {props.user && <Line>Log Out</Line>}
    </Container>
    <Link onClick={props.logout()}>
      <ImageContainer />
    </Link>
  </>
);
const NoPowerPage = () => {
  const { logout, user } = simonAuth();
  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    document.body.style.backgroundColor = '#eaf2fe';
  });

  return noPower({ user, logout });
};

export default NoPowerPage;
