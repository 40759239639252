import React, { useEffect, useState } from 'react';
import { borrowPowerApi } from '../../api/history';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Container } from './style';

import Loading from '../Loading';

HighchartsMore(Highcharts);

export default function ChartBorrow() {
  const [borrow, setLastBorrow] = useState([]);

  useEffect(() => {
    borrowPowerApi()
      .then((data) => setLastBorrow(data))
      .catch(console.log);
  }, []);

  if (borrow.length === 0) {
    return <Loading />;
  } else {
    const chartOptions = {
      navigator: { enabled: false },
      rangeSelector: {
        buttons: [
          {
            type: 'week',
            count: 1,
            text: 'Week',
          },
          {
            type: 'month',
            count: 1,
            text: 'Month',
          },
          {
            type: 'all',
            text: 'All',
          },
          {
            type: 'ytd',
            text: 'YTD',
          },
        ],
        buttonTheme: { width: 60 },
        selected: 1,
        inputEnabled: false,
      },
      title: { text: 'Borrow' },
      subtitle: { text: '' },
      xAxis: { type: 'datetime', title: { text: '' } },
      yAxis: [
        { title: { text: 'USDT' }, opposite: false, type: 'logarithmic' },
        // { title: { text: 'Available' }, opposite: false },
      ],
      plotOptions: {
        series: {
          dataGrouping: { enabled: false },
          marker: { enabled: false },
        },
      },
      legend: {
        enabled: false,
      },

      series: [
        {
          type: 'area',
          name: 'Borrowed',
          id: 'Borrowed',
          data: borrow.map((e) => [e.date * 1000, Math.abs(e.borrowed)]),
          color: '#7f0000',
          lineWidth: 1,
          yAxis: 0,
          zIndex: 1,
        },
        {
          type: 'area',
          name: 'Max',
          id: 'Max',
          data: borrow.map((e) => [
            e.date * 1000,
            e.available + Math.abs(e.borrowed),
          ]),
          color: '#314774',
          lineWidth: 1,
          yAxis: 0,
          zIndex: 0,
          connectNulls: true,
        },
      ],
      tooltip: {
        enabled: true,
        shared: true,
        distance: 50,
        padding: 10,
        formatter: function () {
          const points = this.points;
          const pointsLength = points.length;
          let tooltipMarkup = pointsLength
            ? `<span style="font-size: 10px">${Highcharts.dateFormat(
                '%d/%m/%Y %H:%M',
                points[0].key
              )} </span><br/>`
            : '';
          let index;

          for (index = 0; index < pointsLength; index += 1) {
            const p = points[index];
            const val = parseFloat(p.y).toFixed(2);
            tooltipMarkup += `<span style="color:${p.series.color}'">\u25CF</span>${p.series.name}: <b>${val}</b><br/>`;
          }

          return tooltipMarkup;
          /*
          const splitFormatter = function (p) {
            if (typeof p === 'undefined') return '';
            return `<br><span style="color:'${p.series.color}"><strong>${
              p.series.name
            }</strong> ${parseFloat(p.y).toFixed(2)}</span>`;
          };
          return [
            Highcharts.dateFormat('%d/%m/%Y %H:%M', this.x) + '<br/>',
            splitFormatter(this.points[0]),
            splitFormatter(this.points[1]),
          ]; */
        },
      },
      scrollbar: { enabled: false },
      exporting: { enabled: false },
      credits: {
        enabled: false,
      },
    };
    return (
      <Container>
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { width: '100%' } }}
          options={chartOptions}
        />
      </Container>
    );
  }
}
