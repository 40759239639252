import React, { useEffect, useState } from 'react';
import {
  consolidated as consolidatedApi,
  getAportesApi,
  wallet as walletApi,
} from '../../api/history';

import { consolidated as lastConsolidatedApi } from '../../api/last';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import { getDate } from '../../utils';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import indicators from 'highcharts/indicators/indicators';
import { getTotalAportes } from '../../utils';
import Loading from '../Loading';
import { Container } from './style';

indicators(Highcharts);

export default function ChartLastDaysPerform() {
  const [consolidated, setConsolidated] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [lastPosition, setLastPosition] = useState(null);
  const [aportesTotais, setAportesTotais] = useState([]);
  const [showFundraising, setShowFundraising] = useState(false);

  useEffect(() => {
    consolidatedApi()
      .then((data) => setConsolidated(data))
      .catch(console.log);
    walletApi()
      .then((data) => setWallet(data))
      .catch(console.log);
    lastConsolidatedApi()
      .then((data) => setLastPosition(data))
      .catch(console.log);
    getAportesApi()
      .then((data) => setAportesTotais(data))
      .catch(console.log);
  }, []);

  function handleButton(e) {
    e.preventDefault();
    setShowFundraising(!showFundraising);
  }

  if (consolidated.length === 0 || wallet.length === 0 || !lastPosition) {
    return <Loading />;
  } else {
    /* const aportes = consolidated.map((e) => {
      const dt = Date.parse(getDate(e.date.split('T')[0].replace(/-/g, ''), 1));
      const aporte = getTotalAportes(dt, aportesTotais);
      return [dt, aporte];
    }); */
    // console.log('aportes', aportes);
    // console.log('consolidated', consolidated);

    const timezone = 3 * 60 * 60 * 1000;
    const walletTotalValue = wallet.map((e) => {
      const dt = parseFloat(e.ts) - timezone;
      const aporte = !showFundraising
        ? getTotalAportes(dt + timezone, aportesTotais)
        : 0;
      return [dt, parseFloat(e.tot) - aporte];
    });
    // .filter((e) => e[1] > 100); // W.A

    // console.log('walletTotalValue', walletTotalValue);

    const walletAtMarkPrice = consolidated.map((e) => {
      const dt = Date.parse(e.date) - timezone;
      const aporte = !showFundraising
        ? getTotalAportes(dt + timezone, aportesTotais)
        : 0;
      return [
        // Date.parse(e.date),
        dt,
        Number(e.netValue.toFixed(2)) - aporte,
      ];
    });
    const totalAporteLastDay = !showFundraising
      ? getTotalAportes(Date.parse(lastPosition.date) - timezone, aportesTotais)
      : 0;
    walletAtMarkPrice.push([
      walletTotalValue.slice(-1)[0][0],
      lastPosition.netValue - totalAporteLastDay,
    ]);

    const chartOptions = {
      navigator: { enabled: false },
      rangeSelector: {
        buttons: [
          {
            type: 'hour',
            count: 12,
            text: '12h',
          },
          {
            type: 'hour',
            count: 48,
            text: '48h',
          },
          {
            type: 'week',
            count: 1,
            text: 'Week',
          },
          {
            type: 'month',
            count: 1,
            text: 'Month',
          },
          {
            type: 'all',
            text: 'All',
          },
          {
            type: 'ytd',
            text: 'YTD',
          },
        ],
        buttonTheme: { width: 60 },
        selected: 2,
        inputEnabled: false,
      },
      title: { text: 'Performance' },
      subtitle: { text: '' },
      xAxis: { type: 'datetime', title: { text: '' } },
      yAxis: { title: { text: '' } },
      plotOptions: { series: { marker: { enabled: false } } },

      legend: {
        floating: true,
        enabled: true,
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        x: 60,
      },
      series: [
        {
          name: 'Wallet',
          id: 'wallet',
          data: walletTotalValue,
          color: '#314774',
          lineWidth: 1,
          opacity: 0.1,
          yAxis: 0,
        },
        {
          name: '@ Mark Price',
          data: walletAtMarkPrice,
          color: '#38F568',
          visible: false,
        },
        {
          type: 'sma',
          name: 'Crypto Strategy',
          linkedTo: 'wallet',
          zIndex: 1,
          lineWidth: 3,
          opacity: 1,
          yAxis: 0,
          params: {
            period: 10, // 8horas
          },
        },
      ],
      tooltip: {
        enabled: true,
        split: true,
        distance: 50,
        padding: 10,
        formatter: function () {
          const splitFormatter = function (p) {
            if (typeof p === 'undefined') return '';
            if (p.series.name.indexOf('Wallet') === 0) return '';
            return (
              '<strong>' +
              p.series.name +
              '</strong>' +
              '<br /><strong>' +
              parseFloat(p.y).toFixed(2)
            );
          };
          return [
            Highcharts.dateFormat('%d/%m/%Y %H:%M', this.x),
            splitFormatter(this.points[0]),
            splitFormatter(this.points[1]),
            splitFormatter(this.points[2]),
            splitFormatter(this.points[3]),
          ];
        },
      },
      scrollbar: { enabled: false },
      exporting: { enabled: false },
      credits: {
        enabled: false,
      },
    };

    return (
      <Container>
        {showFundraising && (
          <FormControlLabel
            control={
              <Switch
                checked={showFundraising}
                onChange={handleButton}
                name="checkedB"
                color="primary"
              />
            }
            label="Fundraising"
          />
        )}
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          containerProps={{ style: { width: '100%' } }}
          options={chartOptions}
        />
      </Container>
    );
  }
}
