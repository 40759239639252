import React, { useEffect, useState } from 'react';
// import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { borrowPowerApi, margin } from '../../api/last';
import Loading from '../Loading';

function MarginCard(props) {
  const [state, setState] = useState({
    loading: true,
    margins: [],
    pct: false,
  });

  useEffect(() => {
    const marginType = props.marginType;
    if (props.marginType === 'borrow') {
      borrowPowerApi().then((data) => {
        setState({
          loading: false,
          pct: !data.borrowed
            ? 0
            : Number(
                Number(
                  (Math.abs(data.borrowed) * 100) /
                    (Math.abs(data.borrowed) + data.available)
                ).toFixed(2)
              ),
        });
      });
    } else {
      margin()
        .then((data) => {
          setState({ loading: false, pct: data.margins[marginType] });
        })
        .catch(console.log);
    }
  }, [props.marginType]);

  if (!state || state.loading || state.pct === false) return <Loading />;
  const marginType = props.marginType;
  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textSecondary" gutterBottom variant="subtitle2">
            {marginType.toUpperCase()}
          </Typography>
          <Typography color="textPrimary" variant="body2">
            {state.pct}%
          </Typography>
        </Grid>
        {!Number.isNaN(state.pct) && (
          <LinearProgress value={state.pct} variant="determinate" />
        )}
      </CardContent>
    </Card>
  );
}

MarginCard.propTypes = {
  // classes: PropTypes.object.isRequired,
  marginType: PropTypes.string,
};

export default MarginCard;
